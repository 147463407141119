<template>
  <div class="footer-wrap">
    <!-- 左侧 -->
    <div class="foot-box">
      <div class="foot-logo">
        <img :src="mini_logo" />
      </div>

      <div class="foot-introduction">
        <div class="foot-introduction-us">
          {{
            this.$store.state.nowLanguage
              ? "上海观理碳科技有限公司"
              : "Carbongram Shanghai Co., Ltd"
          }}
        </div>
        <div class="foot-introduction-us">
          Copyright &copy; 2020-2023 ccer.com
        </div>
        <div class="foot-introduction-us usOpen" @click="openOne()">
          沪ICP备2022000115号-2
        </div>
        <div class="police_number" @click="openTwo()">
          <img src="@/assets/home/background/police_tag.png" alt="" />
          公安备案号31011002005944号
        </div>
      </div>

      <div class="foot-information">
        <div class="foot-message">
          <img src="@/assets/images/phone-foot.png" alt="" />
          <span>400 9696 179</span>
        </div>
        <div class="foot-message">
          <img src="@/assets/images/mail-foot.png" alt="" />
          <span>info@ccer.com</span>
        </div>
      </div>

      <div class="foot-qrcode">
        <div class="foot-qrcode-box">
          <img :src="qrcode_1" alt="" />
          <p>
            {{
              this.$store.state.nowLanguage
                ? "关注公众号"
                : "Follow us on WeChat"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-phone">
    <div class="phone-box">
      <div class="phone-box-left">
        <div class="foot-logo">
          <img :src="phone_logo" />
        </div>
        <div class="foot-information">
          <div class="foot-message">
            <img src="@/assets/images/phone-foot.png" alt="" />
            <span>400 9696 179</span>
          </div>
          <div class="foot-message">
            <img src="@/assets/images/mail-foot.png" alt="" />
            <span>info@ccer.com</span>
          </div>
        </div>
      </div>
      <div class="phone-box-right">
        <div class="foot-qrcode">
          <div class="foot-qrcode-box">
            <img :src="qrcode_1" alt="" />
            <p>
              {{
                this.$store.state.nowLanguage
                  ? "关注公众号"
                  : "Follow us on WeChat"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-introduction">
      <div class="bottom-introduction-us">上海观理碳科技有限公司</div>
      <div class="bottom-introduction-us">
        Copyright &copy; 2020-2023 ccer.com
      </div>
      <div class="bottom-introduction-us usOpen" @click="openOne()">
        沪ICP备2022000115号-2
      </div>
      <div class="bottom-introduction-us usOpen" @click="openTwo()">
        <img src="@/assets/home/background/police_tag.png" alt="" />
        公安备案号31011002005944号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },
  },
  data() {
    return {
      mini_logo: require("@/assets/images/logo.png"),
      phone_logo: require("@/assets/images/logo.png"),
      qrcode_1: require("@/assets/home/icons/Official_Public_Account.png"),
      qrcode_2: require("@/assets/home/icons/Official_Enterprise_WeChat.png"),
    };
  },
  mounted() {},
  methods: {
    openOne() {
      window.open("https://beian.miit.gov.cn");
    },
    openTwo() {
      window.open(
        "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005944"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  padding: 0px 12.5%;
  padding-top: 70px;
  width: 100%;
  height: 266px;
  background: #252c3e;
  box-sizing: border-box;
  text-align: left;
  .foot-box {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-right: 0%;
    margin-bottom: 20px;
    min-width: 220px;
    color: #fff;
    font-size: 18px;
    .foot-logo {
      width: 118px;
      height: 42px;
      img {
        width: 100%;
      }
    }
    .foot-information {
      .foot-message {
        display: flex;
        margin-bottom: 6px;
        img {
          width: 25px;
          height: 25px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 25px;
          letter-spacing: 1px;
          margin-left: 12px;
        }
      }
    }
    .foot-introduction {
      &-us {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }
      .usOpen {
        cursor: pointer;
        user-select: none;
      }
      .police_number {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 1px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        user-select: none;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
    .foot-qrcode {
      &-box {
        text-align: center;
        img {
          width: 110px;
          height: 110px;
        }
        p {
          font-size: 14px;
          text-align: center;
          font-weight: 400;
          color: #ffffff;
          line-height: 18px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
.footer-phone {
  display: none;
}
</style>
<style lang="scss" scoped>
.mobile {
  .footer-wrap {
    display: none;
  }
  .footer-phone {
    display: block;
    width: 100%;
    height: 294px;
    .phone-box {
      width: 100%;
      height: 158px;
      background: #252c3e;
      display: flex;
      justify-content: space-between;
      padding: 0 4.3%;
      padding-top: 30px;
      &-left {
        .foot-logo {
          width: 84px;
          height: 30px;
          img {
            width: 100%;
          }
        }
        .foot-information {
          margin-top: 18px;
          .foot-message {
            display: flex;
            margin-bottom: 6px;
            img {
              width: 25px;
              height: 25px;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              text-align: left;
              color: #ffffff;
              line-height: 25px;
              letter-spacing: 1px;
              margin-left: 12px;
            }
          }
        }
      }
      &-right {
        .foot-qrcode {
          &-box {
            text-align: center;
            img {
              width: 77px;
              height: 77px;
            }
            p {
              font-size: 10px;
              text-align: center;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
              letter-spacing: 0.8px;
              margin-top: 4px;
            }
          }
        }
      }
    }
    .bottom-introduction {
      width: 100%;
      height: 136px;
      background: #f5f7fa;
      text-align: center;
      padding-top: 20px;
      &-us {
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: #999999;
        line-height: 24px;
        letter-spacing: 0.8px;
        display: flex;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
      .usOpen {
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>

<template>
  <div :class="classObj" class="app-wrap">
    <!-- <Mask v-if="is_mask" /> -->
    <Header class="header-wrap" />
    <div class="main-container">
      <app-main />
    </div>
    <Footer />
  </div>
</template>

<script>
import Mask from "@/components/mask";
import { Header, AppMain, Footer } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";

export default {
  name: "Layout",
  components: {
    Mask,
    Header,
    Footer,
    AppMain,
  },
  mixins: [ResizeMixin],
  computed: {
    is_mask() {
      return this.$store.state.app.is_mask;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.app-wrap {
  user-select: none;
  @include clearfix;
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  min-width: 1440px;
  font-family: HarmonyOS_Sans_SC_Regular;
 
  &.mobile {
    width: 100%;
    min-width: 360px;
  }
}
</style>

<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // console.log(this.$store.state.nowLanguage);
    // console.log(window.navigator.language);
    if (sessionStorage["isLanguage"]) {
      // 刷新语言不变
      if (sessionStorage["isLanguage"] == "zh-CN") {
        this.$store.commit("saveLanguage", true);
      } else if (sessionStorage["isLanguage"] == "en") {
        this.$store.commit("saveLanguage", false);
      }
    } else {
      // 根据浏览器默认语言显示
      if (window.navigator.language == "zh-CN") {
        this.$store.commit("saveLanguage", true);
        sessionStorage.setItem("isLanguage", "zh-CN");
      } else if (window.navigator.language == "en") {
        this.$store.commit("saveLanguage", false);
        sessionStorage.setItem("isLanguage", "en");
      }
    }
  },
  methods: {},
};
</script>
<style>
body {
  background: #ecedef;
  height: 100%;
  overflow-x: overlay;
  overflow-y: scroll;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.el-dropdown-menu {
  font-family: HarmonyOS_Sans_SC_Regular;
  background-color: #ffffff !important;
}
/* 下拉菜单字体颜色 */
.el-dropdown-menu__item:not(.is-disabled):hover,
.el-dropdown-menu__item:focus {
  background-color: rgba(13, 184, 124, 0.1);
  color: rgb(13, 184, 124);
}
</style>

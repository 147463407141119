<template>
  <div id="mask" class="mask" @touchmove.prevent>
    <div class="poster">
      <div class="inner-mask"></div>
      <img v-if="url" :src="url" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["url"],
  data() {
    return {};
  },
  mounted () {
    // document.body.style = 'position:fixed; top:0; height: 100%;width:100%;overflow: hidden;'
    document.body.style = 'overflow: hidden;'
  },
};
</script>
<style lang="scss">
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  .poster {
    position: relative;
    width: 100%;
    height: 100%;
    .inner-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 1);
      opacity: 0.4;
      height: 100%;
      width: 100%;
      filter: alpha(opacity=60);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <div
    ref="header-wrap"
    class="header-wrap"
    :class="{ 'header-mini': !headerShow }"
    @mouseleave="handleHidePop(e)"
  >
    <div class="header-box">
      <h1 class="left-box" @click="goHome()">
        <img :src="logo" alt="观碳智能" />
      </h1>
      <div class="center-box">
        <div class="menu">
          <!-- <div
            v-for="(m, i) in menus"
            :key="i"
            class="menu-item"
            @mouseenter="(e) => handleLinkPop(e, i, m)"
            @click="toAbout(i)"
          >
            {{ this.$store.state.nowLanguage ? m.title : m.EnglishTitle }}
          </div> -->
          <div
            v-for="(m, i) in menus"
            :key="i"
            class="menu-item"
            :class="isMenu == i ? 'menuActive' : ''"
            @click="toAbout(m.path, i)"
          >
            {{ this.$store.state.nowLanguage ? m.name : m.EnglishName }}
          </div>
        </div>
      </div>
      <div class="right-box">
        <!-- v-if="isShow" -->
        <div class="language-check" @click="languageChange" v-if="false">
          <img src="@/assets/home/icons/language_logo.png" alt="" />
          <span>{{
            this.$store.state.nowLanguage ? "English" : "中文(简体)"
          }}</span>
        </div>
        <div class="hot-line">
          <span class="fa fa-phone"></span>
          <a href="tel:4009696179">400 9696 179</a>
        </div>
        <!-- <div class="login-btn">
          <el-button type="primary" @click="goLogin()"> 登录 </el-button>
        </div> -->
        <div class="app-btn">
          <el-dropdown
            @command="handleCommand"
            trigger="click"
            ref="dropdownRef"
          >
            <span class="el-dropdown-link">
              <i
                class="ri-menu-line"
                style="font-size: 18px; color: #595c6c"
              ></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in menus"
                  :key="index"
                  :command="index"
                  divided
                  >{{
                    this.$store.state.nowLanguage ? item.name : item.EnglishName
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- <div v-if="pop_links.length > 0" class="menu-pop" :style="pop_positon">
      <div class="menu-pop-item" v-for="(l, j) in pop_links" :key="j">
        <div class="menu-pop-item-title">
          {{ this.$store.state.nowLanguage ? l.title : l.EnglishTitle }}
        </div>
        <div class="menu-pop-item-brief" v-if="l.brief">
          {{ this.$store.state.nowLanguage ? l.brief : l.briefEn }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { thisTypeAnnotation } from "@babel/types";
import links from "./data/links.json";
export default {
  data() {
    return {
      isMenu: 0,
      isShow: true,
      headerShow: false,
      pop_idx: 0,
      pop_left: 0,
      pop_links: [],
      logo: require("@/assets/images/logo.png"),
      links,
      menus: [
        {
          key: "home",
          name: "首页",
          path: "/home",
          EnglishName: "Home",
        },
        {
          key: "product",
          name: "碳资产开发",
          path: "/product",
          EnglishName: "Product",
        },
        {
          key: "transaction",
          name: "碳资产交易",
          path: "/transaction",
          EnglishName: "Transaction",
        },
        {
          key: "about",
          name: "关于我们",
          path: "/about",
          EnglishName: "About",
        },
      ],
    };
  },
  setup() {
    return {};
  },
  computed: {
    pop_positon() {
      let idx = this.pop_idx;
      let left = this.pop_left + "px";
      return "left:" + left;
    },
    device() {
      return this.$store.state.app.device;
    },
  },
  mounted() {
    if (this.device == "desktop") {
      this.handleScroll();
    }

    if (window.location.href.indexOf("/home") != -1) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

    if (window.location.href.indexOf("/home") != -1) {
      this.isMenu = 0;
      sessionStorage.setItem("isMenu", 0);
    } else if (window.location.href.indexOf("/product") != -1) {
      this.isMenu = 1;
      sessionStorage.setItem("isMenu", 1);
    } else if (window.location.href.indexOf("/transaction") != -1) {
      this.isMenu = 2;
      sessionStorage.setItem("isMenu", 2);
    } else if (window.location.href.indexOf("/about") != -1) {
      this.isMenu = 3;
      sessionStorage.setItem("isMenu", 3);
    }
    console.log(this.isMenu, sessionStorage.isMenu);
    this.isMenu = sessionStorage.isMenu ? sessionStorage.isMenu : 0;
  },
  watch: {
    $route(to, from) {
      console.log(to);
      if (to.path.indexOf("/home") != -1) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }

      if (to.path.indexOf("/home") != -1) {
        this.isMenu = 0;
        sessionStorage.setItem("isMenu", 0);
      } else if (to.path.indexOf("/product") != -1) {
        this.isMenu = 1;
        sessionStorage.setItem("isMenu", 1);
      } else if (to.path.indexOf("/transaction") != -1) {
        this.isMenu = 2;
        sessionStorage.setItem("isMenu", 2);
      } else if (to.path.indexOf("/about") != -1) {
        this.isMenu = 3;
        sessionStorage.setItem("isMenu", 3);
      }
      this.isMenu = sessionStorage.isMenu ? sessionStorage.isMenu : 0;
    },
  },
  methods: {
    goLogin() {
      window.open("http://clouddev.carbongram.com");
    },
    languageChange() {
      let isLanguage = !this.$store.state.nowLanguage;
      this.$store.commit("saveLanguage", isLanguage);
      if (this.$store.state.nowLanguage) {
        sessionStorage.setItem("isLanguage", "zh-CN");
      } else {
        sessionStorage.setItem("isLanguage", "en");
      }
    },
    handleScroll() {
      window.addEventListener("scroll", (e) => {
        let y = window.scrollY;
        if (y > 0) {
          this.logo = require("@/assets/images/logo_02.png");
          this.headerShow = true;
        } else {
          this.$refs.dropdownRef.handleClose();
          setTimeout(() => {
            this.logo = require("@/assets/images/logo.png");
            this.headerShow = false;
          }, 0);
        }
      });
    },
    handleHidePop(e) {
      this.pop_links = [];
      console.log("hide pop");
    },
    handleLinkPop(e, i, menu) {
      console.log(i, menu.title);
      if (i == 4 || i == 3) {
        return;
      }
      this.pop_idx = i;
      this.pop_left = e.target.offsetLeft;
      this.pop_links = menu.links;
    },
    // 回到首页
    goHome() {
      this.$router.push("/");
      this.isMenu = 0;
      sessionStorage.isMenu = 0;
      document.documentElement.scrollTop = 0;
    },

    toAbout(pathUrl, index) {
      this.isMenu = index;
      sessionStorage.isMenu = index;
      this.$router.push(pathUrl);
      document.documentElement.scrollTop = 0;
    },
    // 下拉菜单事件
    handleCommand(command) {
      this.isMenu = command;
      sessionStorage.isMenu = command;
      if (command == 0) {
        this.$router.push("/home");
      } else if (command == 1) {
        this.$router.push("/product");
      } else if (command == 2) {
        this.$router.push("/transaction");
      } else if (command == 3) {
        this.$router.push("/about");
      }
      // this.$router.push("/");
      // document.documentElement.scrollTop = 0.01;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrap {
  position: fixed;
  z-index: 99999;
  width: 100%;
  min-width: 1180px;
  top: 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 21px 48px 0px rgba(0, 0, 0, 0.05);
  // backdrop-filter: blur(20px);
}
.header-box {
  margin: 0 auto;
  padding: 0 50px;
  width: 100%;
  // max-width: 1480px;
  height: 65px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .left-box {
    width: 117px;
    height: 42px;
    margin: 0;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .center-box {
    margin-left: 25px;
    white-space: nowrap;
    height: 100%;
    overflow: hidden;
    position: relative;
    &::after {
      position: absolute;
      top: -10px;
      right: 0;
      content: "";
      display: inline-block;
      width: 100px;
      height: 100%;
      margin-top: 10px;
    }
    .menu {
      display: flex;
      font-size: 16px;
      line-height: 28px;
      height: 100%;
      color: #333333;
      align-items: center;
      &-item {
        position: relative;
        margin-left: 48px;
        color: #333333;
        cursor: pointer;
      }
      &-item:hover {
        color: #00a86d;
      }
      .menuActive {
        color: #00a86d;
      }
    }

    flex: 1;
  }

  .right-box {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .language-check {
      font-size: 16px;
      line-height: 28px;
      color: #333333;
      display: flex;
      justify-content: space-around;
      margin-right: 50px;
      position: relative;
      user-select: none;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        line-height: 28px;
        margin-right: 10px;
        position: relative;
        top: 3px;
      }
      span {
        display: inline-block;
        font-size: 16px;
        line-height: 28px;
      }
    }
    .hot-line {
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      color: #009944;

      span {
        margin-right: 3px;
        transform: rotateY(0deg);
      }
    }
    .login-btn {
      margin-left: 50px;
      .el-button {
        font-size: 14px;
        height: 38px;
        line-height: 14px;
        padding: 0 30px;
        background: #f3f3f4;
        border-radius: 8px;
        border: none;
      }
      /* 更改主要按钮颜色 */
      .el-button--primary {
        color: #ffffff;
        background-color: #00a86d;
        border-color: #00a86d;
        border-radius: 8px;
      }

      /* 按钮选中颜色 */
      .el-button--primary:hover,
      .el-button--primary:focus {
        color: #ffffff;
        background: #0db87c;
        border-color: #0db87c;
      }
    }
    .app-btn {
      display: none;
    }
  }
}

.menu-pop {
  position: absolute;
  top: 55px;
  margin-left: 160px;
  padding: 20px 30px;
  width: 340px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.05);
  z-index: 9999;
  text-align: left;

  &-item {
    // padding-bottom: 10px;
    margin-bottom: 30px;
    // border-bottom: 1px solid #f2f2f2;

    &-title {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
    }

    &-brief {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
  }

  &-item:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}
.header-mini {
  background: none;
  box-shadow: none;
  backdrop-filter: none;
  .center-box {
    display: none;
  }
  .right-box {
    display: none;
  }
}
</style>
<style lang="scss">
.el-dropdown-menu__item--divided {
  margin: 4px 0;
}
</style>
<style lang="scss" scoped>
.mobile {
  .header-wrap {
    display: block;
    min-width: 360px !important;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 21px 48px 0px rgba(0, 0, 0, 0.05);
    // backdrop-filter: blur(20px);
  }
  .header-mini {
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    .center-box {
      display: none;
    }
    .right-box {
      display: none;
    }
  }
  .header-box {
    padding: 0 16px;
    height: 58px;
    .center-box {
      display: none;
    }
    .left-box {
      width: 78px;
      height: 28px;
      img {
        width: 100%;
      }
    }
    .center-box {
      display: none;
    }
    .right-box {
      .language-check {
        display: none;
        font-size: 12px;
        line-height: 24px;
        margin-right: 0;
        img {
          width: 20px;
          height: 20px;
          line-height: 24px;
        }
        span {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .hot-line {
        display: none !important;
        font-size: 14px;
        display: "block";
        span {
          transform: rotateY(180deg);
        }
      }
      .login-btn {
        display: none;
        margin-left: 24px;
        .el-button {
          height: 28px;
          padding: 0 20px;
        }
      }
      .app-btn {
        display: block;
      }
    }
  }
}
</style>

import { createApp } from 'vue'
// global css
import 'normalize.css/normalize.css' 
import 'font-awesome/css/font-awesome.css'
import animate from "animate.css";
import '@/styles/index.scss'

// SvgIcon
import '@/assets/icons'
import SvgIcon from '@/components/SvgIcon.vue'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

// element-ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/styles/font/font.scss'
import 'remixicon/fonts/remixicon.css'

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueLoading)
app.use(FloatingVue)
app.use(ElementPlus)
app.component("svg-icon", SvgIcon)
app.mount('#app')

import {
  createStore
} from 'vuex'

import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'

export default createStore({
  state: {
    nowLanguage: true,
  },
  mutations: {
    saveLanguage(state, nowLanguage) {
      state.nowLanguage = nowLanguage;
    },
    // this.$store.commit('saveLanguage', false)
  },
  actions: {},
  modules: {
    app,
    settings,
    getters,
  }
})